<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        lg="4"
      >
        <base-material-chart-card
          :data="YearSalesChart.data"
          :options="YearSalesChart.options"
          :responsive-options="YearSalesChart.responsiveOptions"
          color="#E91E63"
          hover-reveal
          type="Bar"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="info"
                  icon
                  v-on="on"
                >
                  <v-icon color="info"> mdi-refresh </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">Заказы за год</h4>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">{{ $t('LastPerformance') }}</p>

          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="text-caption grey--text font-weight-light">обновлено 10 минут назад</span>
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col
        cols="12"
        lg="4"
      >
        <base-material-chart-card
          :data="WeekSalesChart.data"
          :options="WeekSalesChart.options"
          color="success"
          hover-reveal
          type="Line"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="info"
                  icon
                  v-on="on"
                >
                  <v-icon color="info"> mdi-refresh </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">Заказы за неделю</h4>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            <v-icon
              color="green"
              small
            >
              mdi-arrow-up
            </v-icon>
            <span class="green--text">55%</span>&nbsp; {{ $t('IncrSales') }}
          </p>

          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="text-caption grey--text font-weight-light">обновлено 4 минуты назад</span>
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col
        cols="12"
        lg="4"
      >
        <base-material-chart-card
          :data="DailySalesChart.data"
          :options="DailySalesChart.options"
          hover-reveal
          color="info"
          type="Line"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="info"
                  icon
                  v-on="on"
                >
                  <v-icon color="info"> mdi-refresh </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h3 class="card-title font-weight-light mt-2 ml-2">Заказы за день</h3>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">{{ $t('LastPerformance') }}</p>

          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="text-caption grey--text font-weight-light"
              >данные компаний за последние 26 минут</span
            >
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-instagram"
          :title="$t('Followers')"
          value="+245"
          sub-icon="mdi-clock"
          :sub-text="$t('justUpdated')"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-poll"
          title="Сайт"
          :value="statistics[0].google_analytics_count.toString()"
          sub-icon="mdi-tag"
          :sub-text="$t('googleAnal')"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="success"
          icon="mdi-store"
          title="Доход"
          :value="statistics[0].revenue.toString()"
          sub-icon="mdi-calendar"
          sub-text="За последний день"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="orange"
          icon="mdi-sofa"
          title="Заказы"
          :value="statistics[0].order_count.toString()"
          sub-icon="mdi-alert"
          sub-icon-color="red"
          :sub-text="$t('getSpace')"
        />
      </v-col>

      <v-col
        cols="12"
        md="12"
      >
        <!-- <base-material-card
          color="warning"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">Статистика курьеров</div>

            <div class="text-subtitle-1 font-weight-light">
              New employees on 15th September, 2016
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
            />
          </v-card-text>
        </base-material-card> -->
        <!-- <base-material-card
          color="warning"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">Статистика дня</div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="statisticsHeader"
              :items="statistics"
            />
          </v-card-text>
        </base-material-card> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StatisticsService from '@/app/Services/StatisticsService'

export default {
  name: 'DashboardDashboard',

  data() {
    return {
      WeekSalesChart: {
        data: {
          labels: [],
          series: [[]],
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 50,
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      DailySalesChart: {
        data: {
          labels: [],
          series: [[]],
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      YearSalesChart: {
        data: {
          labels: [],
          series: [[]],
        },
        options: {
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            'screen and (max-width: 640px)',
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            },
          ],
        ],
      },
      headers: [
        {
          sortable: false,
          text: 'ID',
          value: 'id',
        },
        {
          sortable: false,
          text: 'Name',
          value: 'name',
        },
        {
          sortable: false,
          text: 'Salary',
          value: 'salary',
          align: 'right',
        },
        {
          sortable: false,
          text: 'Country',
          value: 'country',
          align: 'right',
        },
        {
          sortable: false,
          text: 'City',
          value: 'city',
          align: 'right',
        },
      ],
      statistics: [],
      // statisticsHeader: [],
      items: [
        {
          id: 1,
          name: 'Dakota Rice',
          country: 'Niger',
          city: 'Oud-Tunrhout',
          salary: '$35,738',
        },
        {
          id: 2,
          name: 'Minerva Hooper',
          country: 'Curaçao',
          city: 'Sinaai-Waas',
          salary: '$23,738',
        },
        {
          id: 3,
          name: 'Sage Rodriguez',
          country: 'Netherlands',
          city: 'Overland Park',
          salary: '$56,142',
        },
        {
          id: 4,
          name: 'Philip Chanley',
          country: 'Korea, South',
          city: 'Gloucester',
          salary: '$38,735',
        },
        {
          id: 5,
          name: 'Doris Greene',
          country: 'Malawi',
          city: 'Feldkirchen in Kārnten',
          salary: '$63,542',
        },
      ],
      tabs: 0,
      tasks: {
        0: [
          {
            text: 'Sign contract for "What are conference organizers afraid of?"',
            value: true,
          },
          {
            text: 'Lines From Great Russian Literature? Or E-mails From My Boss?',
            value: false,
          },
          {
            text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
            value: false,
          },
          {
            text: 'Create 4 Invisible User Experiences you Never Knew About',
            value: true,
          },
        ],
        1: [
          {
            text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
            value: true,
          },
          {
            text: 'Sign contract for "What are conference organizers afraid of?"',
            value: false,
          },
        ],
        2: [
          {
            text: 'Lines From Great Russian Literature? Or E-mails From My Boss?',
            value: false,
          },
          {
            text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
            value: true,
          },
          {
            text: 'Sign contract for "What are conference organizers afraid of?"',
            value: true,
          },
        ],
      },
      list: {
        0: false,
        1: false,
        2: false,
      },
    }
  },

  methods: {
    complete(index) {
      this.list[index] = !this.list[index]
    },
    async getStatisticsDaily() {
      const { data } = await StatisticsService.getStatisticsDaily()

      const charts = ['year_orders', 'week_orders', 'day_orders']

      charts.forEach((chart) => {
        data[chart].forEach((item) => {
          switch (chart) {
            case 'year_orders': {
              this.YearSalesChart.data.labels.push(item.month)
              this.YearSalesChart.data.series[0].push(item.count)
              break
            }
            case 'week_orders': {
              this.WeekSalesChart.data.labels.push(item.day)
              this.WeekSalesChart.data.series[0].push(item.count)
              break
            }
            case 'day_orders': {
              this.DailySalesChart.data.labels.push(item.hour)
              this.DailySalesChart.data.series[0].push(item.count)
              break
            }
          }
        })
      })

      this.WeekSalesChart.options.high = Math.max.apply(null, this.WeekSalesChart.data.series[0])
      this.DailySalesChart.options.high =
        Math.max.apply(null, this.DailySalesChart.data.series[0]) + 10

      // this.statisticsHeader.entries(res.data).map(([text, value]) => ({ text, value }))

      // for (let i = 0; i < Object.keys(res.data).length; i++) {
      //   for (let y = 0; y < Object.keys(res.data)[i]; y++) {
      //     console.log(y)
      //     this.statisticsHeader[i].value = res.data[i]
      //     this.statisticsHeader[i].text = res.data[i]
      //   }
      // }
      // for (var key in res.data) {
      //   this.statisticsHeader.value = res.data[key]
      //   this.statisticsHeader.text = res.data[key]
      //   console.log(key)
      // }

      // for (let i = 0; i < Object.keys(res.data).length; i++) {
      //   // console.log(res.data[i])
      //   this.statisticsHeader.push({
      //     text: '',
      //     value: '',
      //   })
      //   this.statisticsHeader[i].text = Object.entries(res.data)[i][0]
      //   this.statisticsHeader[i].value = Object.entries(res.data)[i][0]
      // }
      this.statistics.push(data)
    },
  },
  mounted() {
    this.getStatisticsDaily()
  },
}
</script>
<style scoped>
@media (max-width: 1740px) {
  .col-lg-4 {
    min-width: 500px !important;
  }
}
</style>
